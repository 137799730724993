// CORE
// exports.coreEndpoint = process.env.NODE_ENV === 'development'
// ?'https://api.dev.quikvz.com/v0'
// :'https://api.quikvz.com/v0'
exports.coreEndpoint = 'https://api.dev.quikvz.com/v0'

exports.contact = '/contact'
exports.form = '/form'
exports.lm = "/lm";
exports.log = "/logs";
exports.metrics = "/metrics";

